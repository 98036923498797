/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components

import PropTypes from "prop-types";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import GeneralMessage from "components/Ponce/GeneralMessage";
import Indicator from "components/Ponce/Indicator";

function AuthHeader({ title, lead }) {
  return (
    <>
    
      <div className="header bg-gradient-info py-7 py-lg-8 pt-lg-9">
        <Container>
        <div className="header-body">
            <Row className="align-items-center ">
              
              <Col className="mt-10 mt-md-0 text-center" sm="12">
              <GeneralMessage/>
              <Indicator/>
               
              </Col>
            </Row>
          </div>
       
         
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
    </>
  );
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
};

export default AuthHeader;
