/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeaderPonce.js";
//Added
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { setIndicator } from "redux/slices/IndicatorSlice";
import { setMessage } from "redux/slices/MessageSlice";
import { setToken } from "redux/slices/TokenSlice";


function Login() {
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { ip } = useSelector((state) => state.ip);
  const { token } = useSelector((state) => state.token);
  const [loginInfo, setLoginInfo] = useState({
    action:"login",
    email: "",
    password: "",
    is_reset_button_showing:false
    
  });


  React.useEffect(() => {
     if ( token.hasOwnProperty("roles") ) {
          const admin = token.roles.filter(d=>{
            return d.role_id === "1000" //Admin
          })
          if (admin.length > 0) {
            history.push("/admin/dashboard");
          }
          const store = token.user[0].company.filter(d=>{
            return d.company_type === '1' //Store
          })
          if (store.length > 0) {
            history.push("/admin/locations");
          }
          const inventory = token.user[0].company.filter(d=>{
            return d.company_type === '2' //Inventory
          })
          if (inventory.length > 0) {
            history.push("/auth/upload");
          }


          //console.log(admin,admin.length );
        } 
  }, [token]);

  

  function handleChange(event) {
    //console.log("handleAreaChange")
    //console.log(event.target);
    const {name, value} = event.target;
    setLoginInfo(prevValue =>{
      return {
        ...prevValue,
        [name]:value
      }
    })
    //console.log(areas);
  }
  function handleClick(event) {
   
   
   if (loginInfo.email.length < 2 || loginInfo.password.length < 8) {
    dispatch(setMessage({ message: "Please check your email address and password needs to be greater than 8 characters", variant: "danger" }));
    return;
   }
  
   let formData = null;
   formData = new FormData();
        formData.append("action",loginInfo.action);
        formData.append("email", loginInfo.email);
        formData.append("password", loginInfo.password);
        
   ip.length === 1 &&
   axios
     //.post("http://" + ip[0].ip +":" + ip[0].websocket_port +"/login/authAPI.php", formData)
     .post(ip[0].ip +"/login/authAPI.php", formData)
     .then((response) => {
       

       if (response.data.status === "success") {
        response.data.user[0].company.forEach(d=>{
          d.key = d.company_id +"-" + d.store_number+"-" + d.start_date;
        })
         dispatch(setToken(response.data))
         //const tmpToken = response.data;
         


         //history.push("/admin/dashboard");
         dispatch(
          setMessage({ message: response.data.message+"", variant: "info" })
        );



       



       } else {
        setLoginInfo(prevValue =>{
          return {
            ...prevValue,
            is_reset_button_showing:true
          }
        })
        dispatch(
          setMessage({ message: response.data.message+"", variant: "danger" })
        );

       }
       
       
       //dispatch(setCustomers(response.data.data))
       dispatch(setIndicator(false));
       //hideModal(false)
       //callback(response.data);
     })
     .catch((error) => {
       dispatch(setIndicator(false));
       dispatch(setMessage({ message: error, variant: "danger" }));
       console.log(error);
       //console.log("*****  "+error)
     });
   


   //ip.length === 1 && customer.customer_name.length > 0 &&

   //dispatch(setIndicator(true));
  }
  
  function handleResetClick(event) {
    
    
   if (loginInfo.email.length < 2 || loginInfo.password.length < 8) {
    dispatch(setMessage({ message: "Please check your email address and password needs to be greater than 8 characters", variant: "danger" }));
    return;
   }
   
   let formData = null;
   formData = new FormData();
        formData.append("action","reset");
        formData.append("loginHome", window.location.href.includes("localhost")?"http://localhost:3000/auth/login":"https://www.countresults.com/bc/auth/login");
        formData.append("email", loginInfo.email);
        
        
        
        
        
   ip.length === 1 &&
   axios
     //.post("http://" + ip[0].ip +":" + ip[0].websocket_port +"/login/authAPI.php", formData)
     .post(ip[0].ip+"/login/authAPI.php", formData)
     .then((response) => {
       

       if (response.data.status === "success") {
         //dispatch(setToken(response.data))
         dispatch(
          setMessage({ message: response.data.message+"", variant: "info" })
        );
       } else {
        dispatch(
          setMessage({ message: response.data.message+"", variant: "danger" })
        );

       }
       
       
       //dispatch(setCustomers(response.data.data))
       dispatch(setIndicator(false));
       //hideModal(false)
       //callback(response.data);
     })
     .catch((error) => {
       dispatch(setIndicator(false));
       dispatch(setMessage({ message: error, variant: "danger" }));
       console.log(error);
       //console.log("*****  "+error)
     });
   


   //ip.length === 1 && customer.customer_name.length > 0 &&

   //dispatch(setIndicator(true));
  }


  return (
    <>
      <AuthHeader
        title="Welcome!"
        lead="User your email and password to login to the portal"
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              
              <CardBody className="px-lg-5 py-lg-5">
                
                <Form role="form">
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        name="email"
                        value={loginInfo.email}
                        onChange={handleChange}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={loginInfo.password}
                        onChange={handleChange}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  {/* <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div> */}
                  <div className="text-center">
                    
                    <Button disabled={loginInfo.email.length > 0 && loginInfo.password.length >7 ? false:true } className="my-4" color="info" type="button" onClick={handleClick} >
                      Sign in
                    </Button>
                    {loginInfo.is_reset_button_showing && (
                      <Button
                       className="my-4" color="info" type="button"
                        onClick={handleResetClick}
                      >
                        Reset
                      </Button>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
            {/* <Row className="mt-3">
              <Col xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
              <Col className="text-right" xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Create new account</small>
                </a>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
