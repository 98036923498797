import { createSlice } from "@reduxjs/toolkit";

const retailInventorySlice = createSlice({
  name: "retailInventory",
  initialState: {retailInventory:[]},
  reducers: {
    createFile(){},
    getRetailInventory() {
      
    },
    processInventory(){},

    
    setRetailInventory(state, action) {
     
      const customersData = action.payload;
     
     
      return  {...state, retailInventory:customersData};
    },
    updateInventory(){}
    //viewInventoryOutput(){},
    
    
   
    
  },
});

export const { createFile, getRetailInventory,  setRetailInventory, processInventory, updateInventory } = retailInventorySlice.actions;

export default retailInventorySlice.reducer;
