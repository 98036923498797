import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
  name: "token",
  //initialState: { ip:[{id:0,ip:"99.99.99.99",websocket_port:0}]},
  initialState: { token:{jwt:""}},
  reducers: {
   
    
    
    setToken(state, action) {
     
      const tokenData = action.payload;
      //ipData.websocket_port = ipData.websocket_port * 1;
      return { ...state, token:tokenData};
    }
  },
});

export const { setToken} = tokenSlice.actions;

export default tokenSlice.reducer;
