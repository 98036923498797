/*!


*/
import React from "react";

import axios from 'axios';




import { useSelector, useDispatch } from "react-redux";


import ItemMasterTableView from "components/Ponce/ItemMaster/ItemMasterTableView"
import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";

import { Card,Button,  Form, CardBody, FormGroup, Input } from "reactstrap";



import { viewItemMasterItems } from "redux/slices/ItemMasterSlice";
import { setMessage } from "redux/slices/MessageSlice";
import { setIndicator } from "redux/slices/IndicatorSlice";




//core components


function ItemMaster({showLog}) {
  const dispatch = useDispatch();

  const { ip } = useSelector((state) => state.ip);
  const { token } = useSelector((state) => state.token);
  const [companies, setCompanies] = React.useState([]);
  const [companyLocations, setCompanyLocations] = React.useState([]);
  const [info, setInfo] =  React.useState({
    
    company:0,
    company_location:0
    
  });

  React.useEffect(() => {
    

  }, [dispatch, ip]);

  React.useEffect(() => {
    const fetchData = async () => {
      
      let formData = null;

      console.log("url", ip[0].ip+"/inventory/inventoryAPI.php")
      formData = new FormData();
        formData.append("action","getStores");
        //formData.append("action","getStores");
        formData.append("jwt", token.jwt);
        //formData.append("password", loginInfo.password);
      //const result = await axios( "http://" +ip[0].ip +":" + ip[0].websocket_port + "/inventory/inventoryAPI.php");
      ip.length === 1 && token.jwt.length > 0 &&
   axios
     //.post("http://" + ip[0].ip +":" + ip[0].websocket_port +"/inventory/inventoryAPI.php", formData)
     .post(ip[0].ip+"/inventory/inventoryAPI.php", formData)
     .then((response) => {
       

       if (response.data.status === "success") {
         //dispatch(setToken(response.data))
         setCompanies(response.data.stores);
         dispatch(
          setMessage({ message: response.data.message+"1", variant: "info" })
        );
       } else {
        dispatch(
          setMessage({ message: response.data.message+"2", variant: "danger" })
        );

       }
       
       
       //dispatch(setCustomers(response.data.data))
       dispatch(setIndicator(false));
       //hideModal(false)
       //callback(response.data);
     })
     .catch((error) => {
       dispatch(setIndicator(false));
       dispatch(setMessage({ message: error, variant: "danger" }));
       console.log(error);
       //console.log("*****  "+error)
     });

      //setInitializationData(result.data.data);
      
    };
    ip.length === 1 && fetchData();
  }, [dispatch, ip, token.jwt.length]);

  React.useEffect(() => {
    
    if (info.company * 1 !== 0) {
      //const locations = [];
      companies.every(d=>{
        //console.log(companies, d, info);
        if (info.company * 1  === d.company_id * 1) {
          //setCompany(d);
          setCompanyLocations(d.locations);
          return false
        } else {
          return true;
        }
      })

      

    } else {
      setCompanyLocations([]);
      setInfo(prevValue =>{
        return {
          ...prevValue,
          company_location:0
        }
      })
    }
    
    
  }, [companies, info.company]);


  
  function handleChange(event) {
    //console.log("handleAreaChange")
    
    const {name, value} = event.target;
    setInfo(prevValue =>{
      return {
        ...prevValue,
        [name]:value
      }
    })
   
  }
  function handleClick(event) {
    
    ip.length === 1 && 
    dispatch(
      viewItemMasterItems({
        action: "viewFile",
        url: ip[0].ip,
        customerID: 0,
        page:"home",
        fileType: "CSV",
        dir: window.location.href.includes("localhost")?"":"bc" ,
        fileName:  "ITEM_MASTER_" + info.company + "_" + info.company_location + ".CSV",
      })
    ); 
  }
 




    
  return (
    <>
      <SimpleHeader name="" parentName="" />
      <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form>
                  <FormGroup>
                    <label htmlFor="company">Select Company</label>
                    <Input
                      name="company"
                      onChange={handleChange}
                      id="company"
                      value={info.company}
                      type="select"
                    >
                     <option value="0">Select Company</option>
                      {companies.map((d) => (
                        <option key={d.company_id} value={d.company_id}>{d.company_name}</option>
                      ))}

                      
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="company">Select company location</label>
                    <Input
                      name="company_location"
                      onChange={handleChange}
                      id="company_location"
                      value={info.company_location}
                      type="select"
                    >
                     <option value="0">Select location</option>
                      {companyLocations.map((d) => (
                        <option key={d.store_number} value={d.store_number}>{d.store_number + "-" + d.city}</option>
                      ))}

                      
                    </Input>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                     disabled={
                        info.company > 0 && info.company_location.length > 0
                          ? false
                          : true
                      }
                      id="load-inventory"
                      className="mt-4"
                      color="info"
                      type="button"
                      onClick={handleClick}
                    >
                     Load File
                    </Button>
                    </div>
                

                  
                  
                </Form>
              </CardBody>
            </Card>
      

      {/* <div className="content">
        <Card>
          <Buttons pageName={"itemMaster"} />
        </Card>
      </div> */}

      <ItemMasterTableView />
    </>
  );
}

export default ItemMaster;
