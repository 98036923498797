/*!


*/
import React from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";




function Details({ data, fileName }) {
 

  //const [fileName, setFileName] = React.useState("details.csv");
  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "inv_sequence",
      text: "Seq",
      sort: true,
      hidden: true,
      csvType: "number",
      headerAlign: "right",
      align: "right",
    },
    {
      dataField: "item_id",
      text: "Item",
      hidden: false,
      csvType: "number",
      sort: true,
    },
  ]);




  //These are necessary for the table
  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,

    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  React.useEffect(() => {
    if (data.length > 0) {
        const cols = [];
        const keys = Object.keys(data[0]);
        for (let i = 0; i < keys.length; i++) {
            const a = isRegProperty(data[0][keys[i]]) === "number" ? "right" : "left";
            const isDummy = keys[i].includes("search") || keys[i].includes("key")  ? true:false;
            let c = {
              dataField: keys[i],
              text: keys[i],
              sort: true,
              hidden: isDummy,
              headerAlign: a,
              align: a,
              editable: false,
              csvType: "number",
            };
            cols.push(c)
        }
        setTableColumns(cols);
    }
  }, [data]);

  function isRegProperty(obj) {
    var t = typeof obj;
    return t;
  }

  return (
    <>
    <ToolkitProvider
            data={data}
            keyField="key"
            columns={tableColumns}
            search
            exportCSV={{
              //fileName: "areaTotals.csv",
              fileName: fileName,
              separator: ",",
              blobType: "text/csv;charset=ansi",
            }}
          >
            {(props) => (
              <div className="py-4 table-responsive">
                <div
                  id="datatable-basic_filter"
                  className="dataTables_filter px-4 pb-1"
                >
                  <label>
                    Search:
                    <SearchBar
                      className="form-control-sm"
                      placeholder=""
                      {...props.searchProps}
                    />
                  </label>
                  <br />
                  <ExportCSVButton {...props.csvProps}>
                    Export {fileName}
                  </ExportCSVButton>
                </div>

                <BootstrapTable
                  {...props.baseProps}
                  pagination={pagination}
                  striped
                  bootstrap4={true}
                  bordered={false}
                  
                />
              </div>
            )}
          </ToolkitProvider>
    </>
  );
}

export default Details;