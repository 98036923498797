import React from "react";

import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";

import { useDispatch, useSelector } from "react-redux";

// reactstrap components
import { Card, Container, CardHeader, Row, Col } from "reactstrap";

import crossfilter from "crossfilter2";
import * as d3 from "d3";
// core components
import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";



function Departments() {
 
  var formatDecimal2 = d3.format(",.2f");
  var formatDecimal = d3.format(",.0f");

  //const fileName = "areas_totals.csv";
  //const tableTitle = "Area Totals";
  //const keyTitle = "Area";
  const itemInfo = {fileName:"department_totals.csv",
                    tableTitle:"Department Totals",
                    keyTitle:"Department",
                    item:"department",
                    itemDescription:"department_description",
                    itemPath1:"department/",
                    itemPath2:"details/department/"}

  const { retailInventory } = useSelector((state) => state.retailInventory);
  

  //console.log(retailInventory[0]);

  const ndx = crossfilter(retailInventory);
  const groupAll = ndx.groupAll();

  const typeDim = ndx.dimension((d) => {
    if (d[itemInfo.item] === null) {
      return '000'
    } else {
      //console.log(d[itemInfo.item])
      if (d[itemInfo.itemDescription]  === null || d[itemInfo.itemDescription] === "") {
        return d[itemInfo.item] +"-0"
      } else {
        return "" + d[itemInfo.item] + "-" + d[itemInfo.itemDescription]
        //return '0002'
      }
      //return '0001'
      //return d[itemInfo.itemDescription]  === null || d[itemInfo.itemDescription] === "" ? d[itemInfo.item] : d[itemInfo.item] +"_" + d[itemInfo.itemDescription]
    }
    //console.log(d);
    //return  d.department

  }
 

  //d[itemInfo.itemDescription]  === null || d[itemInfo.itemDescription] === ""
    //d.area_description === null || d.area_description === ""
     //? d[itemInfo.item]
     //: d[itemInfo.item] +"-" + d[itemInfo.itemDescription]
    //  : d.inv_area + "-" + d.area_description
  );
  const typeGroup = typeDim.group().reduce(
    function (p, v) {
      ++p.count;
      p.qty += v.inv_quantity;

      //p.total += Math.round(v.inv_quantity * v.item_price * 100) / 100;
      p.total += v.extendedTotal;

      return p;
    },
    function (p, v) {
      --p.count;
      p.qty -= v.inv_quantity;
      //p.total -= Math.round(v.inv_quantity * v.item_price * 100) / 100;
      p.total -= v.extendedTotal;
      return p;
    },
    function () {
      return { count: 0, qty: 0, total: 0 };
    }
  );

  /* React.useEffect(() => {
    retailInventory.length === 0 &&
      ip.length === 1 &&
      dispatch(
        getRetailInventory({
          action: "getRetailInventory",
          method: "get",
          url:
            "http://" +
            ip[0].ip +
            ":" +
            ip[0].websocket_port +
            "/retailInventory",
        })
      );
  }, [dispatch, ip, retailInventory.length]); */

  //These are necessary for the tabl
  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  function columFormatter(cell, row) {
    if (row.key === "9999999999") {
      if (row.key === cell) {
        return "Total";
      } else {
        return cell;
      }
    } else {
      return cell;
    }
  }

  function footerFormatter(column, colIndex, { text }) {
    let countTotal = 0;
    let quantityTotal = 0;
    let grandTotal = 0;
    typeGroup.all().forEach(function (d) {
      countTotal = countTotal + d.value.count;
      quantityTotal = quantityTotal + d.value.qty;
      grandTotal = grandTotal + d.value.total;
    });
    switch (column.dataField) {
      case "value.count":
        return formatDecimal(countTotal);
      case "value.qty":
        return formatDecimal2(quantityTotal);
      case "value.total":
        return formatDecimal2(grandTotal);
      default:
        return "";
    }
  }

  function priceFormatter(cell, row) {
    return formatDecimal2(cell);
  }

  function quantityFormatter(cell, row) {
    return formatDecimal(cell);
  }

  return (
    <>
      <SimpleHeader name="" parentName="Inventory" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            <h3 className="mb-0">{itemInfo.tableTitle}</h3>
            <Row>
                <Col className="bg-light text-center" sm={4}>
                Entries


                </Col>
                <Col className="bg-light text-center"  sm={4}>
                Quantity

                </Col>
                <Col className="bg-light text-center"  sm={4}>
                Amount

                </Col>
                <Col className="text-center" sm={4}>
                {groupAll !== null  && groupAll.reduceCount().value()}


                </Col>
                <Col className="text-center"  sm={4}>
                {groupAll !== null  && formatDecimal2(groupAll.reduceSum((d) => d.inv_quantity).value())}

                </Col>
                <Col className="text-center"  sm={4}>
                {groupAll !== null  && formatDecimal2(
                        groupAll
                          .reduceSum((d) => d.extendedTotal)
                          .value()
                      )}

                </Col>
              </Row>
          </CardHeader>
          <ToolkitProvider
            data={typeGroup.all()}
            keyField="key"
            columns={[
              {
                dataField: "key",
                text: itemInfo.keyTitle,
                sort: true,
                csvType: "number",
                footer: "Total",

                formatter: columFormatter,
              },
              {
                dataField: "value.count",
                text: "Count",
                sort: true,
                headerAlign: "right",
                align: "right",
                csvType: "number",
                formatter: quantityFormatter,
                footer: "",
                footerFormatter: footerFormatter,
                footerAlign: "right",
              },
              {
                dataField: "value.qty",
                text: "Quantity",
                sort: true,
                headerAlign: "right",
                align: "right",
                csvType: "number",
                footer: "",
                footerFormatter: footerFormatter,
                formatter: priceFormatter,
                footerAlign: "right",
              },
              {
                dataField: "value.total",
                text: "Total",
                sort: true,
                headerAlign: "right",
                align: "right",
                csvType: "number",
                footer: "",
                footerFormatter: footerFormatter,
                formatter: priceFormatter,
                footerAlign: "right",
              },
              
            ]}
            search
            exportCSV={{
              fileName: itemInfo.fileName,
              separator: ",",
              blobType: "text/csv;charset=ansi",
            }}
          >
            {(props) => (
              <div className="py-4 table-responsive">
                <div
                  id="datatable-basic_filter"
                  className="dataTables_filter px-4 pb-1"
                >
                  <label>
                    Search:
                    <SearchBar
                      className="form-control-sm"
                      placeholder=""
                      {...props.searchProps}
                    />
                  </label>
                  <br />
                  <ExportCSVButton {...props.csvProps}>
                    Export {itemInfo.fileName}
                  </ExportCSVButton>
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  bootstrap4={true}
                  bordered={false}
                />
              </div>
            )}
          </ToolkitProvider>
        </Card>
      </Container>
    </>
  );
}

export default Departments;
