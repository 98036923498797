/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components

//Added
import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { setIndicator } from "redux/slices/IndicatorSlice";
import { setMessage } from "redux/slices/MessageSlice";



function UploadFile() {
  const [focusedEmail, setfocusedEmail] = React.useState(false);
 

  const [company, setCompany] = React.useState({});
  const [companies, setCompanies] = React.useState([]);
  const [companyLocations, setCompanyLocations] = React.useState([]);
  const [fileName, setFileName] = React.useState("");

  
  

  const dispatch = useDispatch();
  const { ip } = useSelector((state) => state.ip);
  const { token } = useSelector((state) => state.token);
  const [info, setInfo] = useState({
    action:"login",
    email: "gilberto@poncesolutions.com",
    password: "12345678",
    company:0,
    inventory_date:"",
    company_location:0
    
  });

  


  React.useEffect(() => {
    const fetchData = async () => {
      let formData = null;
      formData = new FormData();
        formData.append("action","getStores");
        //formData.append("action","getStores");
        formData.append("jwt", token.jwt);
        //formData.append("password", loginInfo.password);
      //const result = await axios( "http://" +ip[0].ip +":" + ip[0].websocket_port + "/inventory/inventoryAPI.php");
      ip.length === 1 && token.jwt.length > 0 &&
   axios
     //.post("http://" + ip[0].ip +":" + ip[0].websocket_port +"/inventory/inventoryAPI.php", formData)
     .post(ip[0].ip+"/inventory/inventoryAPI.php", formData)
     .then((response) => {
       

       if (response.data.status === "success") {
         //dispatch(setToken(response.data))
         setCompanies(response.data.stores);
         dispatch(
          setMessage({ message: response.data.message+"", variant: "info" })
        );
       } else {
        dispatch(
          setMessage({ message: response.data.message+"", variant: "danger" })
        );

       }
       
       
       //dispatch(setCustomers(response.data.data))
       dispatch(setIndicator(false));
       //hideModal(false)
       //callback(response.data);
     })
     .catch((error) => {
       dispatch(setIndicator(false));
       dispatch(setMessage({ message: error, variant: "danger" }));
       console.log(error);
       //console.log("*****  "+error)
     });

      //setInitializationData(result.data.data);
      
    };
    ip.length === 1 && fetchData();
  }, [dispatch, ip, token.jwt.length]);

  React.useEffect(() => {
    //console.log("here");
    if (token.hasOwnProperty('user') && token.user.length === 1) {
      const userCompany = token.user[0].company_id * 1;
      token.user[0].company.every(d=>{
        
        if (userCompany  === d.id * 1) {
          setCompany(d);
          return false
        } else {
          return true;
        }
      })
      //setCompanies(token.user[0].company);
    }
    
  }, [token]);

  React.useEffect(() => {
    
    if (info.company * 1 !== 0) {
      //const locations = [];
      companies.every(d=>{
        //console.log(companies, d, info);
        if (info.company * 1  === d.company_id * 1) {
          //setCompany(d);
          setCompanyLocations(d.locations);
          return false
        } else {
          return true;
        }
      })

      

    } else {
      setCompanyLocations([]);
      setInfo(prevValue =>{
        return {
          ...prevValue,
          company_location:0
        }
      })
    }
    
    
  }, [companies, info.company]);

  

  function handleChange(event) {
   
    
    const {name, value} = event.target;
    setInfo(prevValue =>{
      return {
        ...prevValue,
        [name]:value
      }
    })
   
  }

   

  function handleClick(event) {
   

    let formData = null;
      formData = new FormData();
        formData.append("action","loadFile");
        formData.append("fileName",fileName);
        formData.append("company",info.company);
        formData.append("inventoryDate",info.inventory_date);
        formData.append("storeNumber",info.company_location);
        //formData.append("action","getStores");
        formData.append("jwt", token.jwt);
        //formData.append("password", loginInfo.password);
      //const result = await axios( "http://" +ip[0].ip +":" + ip[0].websocket_port + "/inventory/inventoryAPI.php");
      dispatch(setIndicator(true));
      ip.length === 1 && token.jwt.length > 0 && fileName.length > 0 &&
   axios
     //.post("http://" + ip[0].ip +":" + ip[0].websocket_port +"/inventory/inventoryAPI.php", formData)
     .post(ip[0].ip  +"/inventory/inventoryAPI.php", formData)
     .then((response) => {
      

       if (response.data.status === "success") {
         //dispatch(setToken(response.data))
         //setCompanies(response.data.stores);
         dispatch(
          setMessage({ message: response.data.message+"", variant: "info" })
        );
       } else {
        dispatch(
          setMessage({ message: response.data.message+"", variant: "danger" })
        );

       }
       
       
       //dispatch(setCustomers(response.data.data))
       dispatch(setIndicator(false));
       //hideModal(false)
       //callback(response.data);
     })
     .catch((error) => {
       dispatch(setIndicator(false));
       dispatch(setMessage({ message: error, variant: "danger" }));
       console.log(error);
       //console.log("*****  "+error)
     });
    //let tmp = null;
    //if (event.target.children.length > 0) {
    //tmp = event.target.id.split("-");
    //} else {
      //tmp = event.target.parentElement.id.split("-");
    //}
    //switch 
    //console.log(tmp);
   
   


   //ip.length === 1 && customer.customer_name.length > 0 &&

   //dispatch(setIndicator(true));
  }
  
  function handleFile(e) {
     
      
    if (e.target.files && e.target.files[0]) {
      
      const file = e.target.files[0];
      //if((!!file.name.match(/(\.|\/)(csv)$/i)) || (!!file.name.match(/(\.|\/)(txt)$/i)) ){
      //if((!!file.name.match(/(item_master\.|\/)(csv)$/i))){
      if (file.name.includes("inventory.csv") || file.name.includes("ITEM_MASTER.CSV") ) {
        
        dispatch(setMessage({message:file.name + " has been selected",variant:"info"}))
        let formData = new FormData();
        //if (file.name.includes("inventory.csv")) {
          formData.append("inventory", e.target.files[0]);

        //} else {
          //formData.append("itemMaster", e.target.files[0]);
        //}
       
        //formData = new FormData();
        formData.append("fileName",file.name);
        formData.append("action","uploadInventory");
        formData.append("storeNumber",info.company_location);
        formData.append("companyID",info.company);
        formData.append("userID",token.user[0].id);
        formData.append("inventoryDate",info.inventory_date);

        //formData.append("email", loginInfo.email);
        //formData.append("password", loginInfo.password);
        // const config = {
        //   headers: {
        //       'content-type': 'multipart/form-data'
        //   }
          
        // }
      //dispatch(setIndicator(true));
       ip.length === 1 &&
      axios
        //.post("http://" + ip[0].ip + ":" + ip[0].websocket_port + "/upload/fileAPI.php", formData)
        .post(ip[0].ip + "/upload/fileAPI.php", formData)
        .then((response) => {
          
          if (response.data.status === "success") {
            setFileName(response.data.fileName);
            dispatch(
              setMessage({ message: response.data.message+"", variant: "info" })
            );

          } else {
            dispatch(
              setMessage({ message: response.data.message+"", variant: "danger" })
            );
          }
          
          dispatch(setIndicator(false));
          //callback(response.data);
        })
        .catch((error) => {
          dispatch(setIndicator(false));
          dispatch(setMessage({ message: error+"", variant: "danger" }));
          console.log(error);
          //console.log("*****  "+error)
        });
      //post("http://localhost:4001/customFile", formData,config) */

        
      } else {
        dispatch(setMessage({message:"Only inventory.csv file is accepted",variant:"danger"}))
        console.log("file does not match");
      }
    }
  }


  return (
    <>
      <SimpleHeader name="" parentName="Points" />
      <Container className="mt--6 pb-5">
        <Row className="justify-content-center">
          <Col sm="12">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form>
                  <FormGroup>
                    <label htmlFor="company">Select Company</label>
                    <Input
                      name="company"
                      onChange={handleChange}
                      id="company"
                      value={info.company}
                      type="select"
                    >
                     <option value="0">Select Company</option>
                      {companies.map((d) => (
                        <option key={d.company_id} value={d.company_id}>{d.company_name}</option>
                      ))}

                      
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="company">Select company location</label>
                    <Input
                      name="company_location"
                      onChange={handleChange}
                      id="company_location"
                      value={info.company_location}
                      type="select"
                    >
                     <option value="0">Select location</option>
                      {companyLocations.map((d) => (
                        <option key={d.store_number} value={d.store_number}>{d.store_number + "-" + d.city}</option>
                      ))}

                      
                    </Input>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="date"
                        name="inventory_date"
                        value={info.inventory_date}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      id="customFileLang"
                      lang="en"
                      type="file"
                      disabled={info.company * 1 !== 0 && info.company_location.length > 0 && info.company_location !== 0?false:true}
                      onChange={handleFile}
                    />

                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Select file
                    </label>
                  </div>
                  <div className="text-center">
                    <Button
                     disabled={
                        fileName.length > 0  && fileName.includes("inventory")
                          ? false
                          : true
                      }
                      id="load-inventory"
                      className="mt-4"
                      color="info"
                      type="button"
                      onClick={handleClick}
                    >
                     Load File
                    </Button>
                    
                      <Button
                        disabled={
                          fileName.length > 0 && fileName.includes("ITEM_MASTER")
                            ? false
                            : true
                        }
                        id="load-itemMaster"
                        className="mt-4"
                        color="info"
                        type="button"
                        onClick={handleClick}
                      >
                        Load Item Master
                      </Button>
                    
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UploadFile;
