/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)

// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts


// core components
import CardsHeader from "components/Headers/CardsHeaderPonce.js";

import {
  chartOptions,
  parseOptions
} from "variables/charts.js";

function Dashboard() {
 
  

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  return (
    <>
      <CardsHeader name="Default" parentName="Dashboards" />
      
    </>
  );
}

export default Dashboard;
