/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState }  from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeaderPonce.js";

//Added
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { setIndicator } from "redux/slices/IndicatorSlice";
import { setMessage } from "redux/slices/MessageSlice";
import { setToken } from "redux/slices/TokenSlice";

function Register() {
  const [focusedName, setfocusedName] = React.useState(false);
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);

  const dispatch = useDispatch();
  const { ip } = useSelector((state) => state.ip);
  const [registrationInfo, setRegistrationInfo] = useState({
    action:"signup",
    user_name:"",
    email: "",
    password: "",
    company:0,
    is_agreeing_to_privacy:false,
    is_reset_button_showing:false
    
  });

  function handleChange(event) {
    //console.log("handleAreaChange")
    //console.log(event.target);
    const {name, value} = event.target;
    setRegistrationInfo(prevValue =>{
      return {
        ...prevValue,
        [name]:value
      }
    })
    //console.log(areas);
  }
  function handleCheckChange(event) {
    //console.log("handleAreaChange")
    //console.log(event.target);
    const {name, checked} = event.target;
    setRegistrationInfo(prevValue =>{
      return {
        ...prevValue,
        [name]:checked
      }
    })
    //console.log(areas);
  }

  function handleClick(event) {
    console.log("handleClick", registrationInfo)
   
   if (registrationInfo.email.length < 2 || registrationInfo.password.length < 8) {
    dispatch(setMessage({ message: "Please check your email address and password needs to be greater than 8 characters", variant: "danger" }));
    return;
   }
   console.log(registrationInfo);
   let formData = null;
   formData = new FormData();
        formData.append("action",registrationInfo.action);
        formData.append("email", registrationInfo.email);
        formData.append("password", registrationInfo.password);
        formData.append("userName", registrationInfo.user_name);
        formData.append("company", registrationInfo.company * 1);
        formData.append("isAgreeingToPrivacy", registrationInfo.is_agreeing_to_privacy?1:0);
        
        
   ip.length === 1 &&
   axios
     //.post("http://" + ip[0].ip +":" + ip[0].websocket_port +"/login/authAPI.php", formData)
     .post(ip[0].ip +"/login/authAPI.php", formData)
     .then((response) => {
       console.log(response.data);

       if (response.data.status === "success") {
         dispatch(setToken(response.data))
         dispatch(
          setMessage({ message: response.data.message+"", variant: "info" })
        );
       } else {
        setRegistrationInfo(prevValue =>{
          return {
            ...prevValue,
            is_reset_button_showing:true
          }
        })
        dispatch(
          setMessage({ message: response.data.message+"", variant: "danger" })
        );

       }
       
      
       //dispatch(setCustomers(response.data.data))
       dispatch(setIndicator(false));
       //hideModal(false)
       //callback(response.data);
     })
     .catch((error) => {
       dispatch(setIndicator(false));
       dispatch(setMessage({ message: error, variant: "danger" }));
       console.log(error);
       //console.log("*****  "+error)
     });
   


   //ip.length === 1 && customer.customer_name.length > 0 &&

   //dispatch(setIndicator(true));
  }
  function handleResetClick(event) {
    console.log("handleClick", registrationInfo)
  
   if (registrationInfo.email.length < 2 || registrationInfo.password.length < 8) {
    dispatch(setMessage({ message: "Please check your email address and password needs to be greater than 8 characters", variant: "danger" }));
    return;
   }
   console.log(registrationInfo);
   let formData = null;
   formData = new FormData();
        formData.append("action","reset");
        formData.append("loginHome","http://localhost:3001/auth/login");
        formData.append("email", registrationInfo.email);
        
        
        
        
        
   ip.length === 1 &&
   axios
     //.post("http://" + ip[0].ip +":" + ip[0].websocket_port +"/login/authAPI.php", formData)
     .post(ip[0].ip+"/login/authAPI.php", formData)
     .then((response) => {
       console.log(response.data);

       if (response.data.status === "success") {
         dispatch(setToken(response.data))
         dispatch(
          setMessage({ message: response.data.message+"", variant: "info" })
        );
       } else {
        dispatch(
          setMessage({ message: response.data.message+"", variant: "danger" })
        );

       }
       
       
       //dispatch(setCustomers(response.data.data))
       dispatch(setIndicator(false));
       //hideModal(false)
       //callback(response.data);
     })
     .catch((error) => {
       dispatch(setIndicator(false));
       dispatch(setMessage({ message: error, variant: "danger" }));
       console.log(error);
       //console.log("*****  "+error)
     });
   


   //ip.length === 1 && customer.customer_name.length > 0 &&

   //dispatch(setIndicator(true));
  }

  return (
    <>
      <AuthHeader
        title="Create an account"
        lead="Once you are gistered, you will get access to load or view data"
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <FormGroup
                    className={classnames({
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Name"
                        type="text"
                        name="user_name"
                        value={registrationInfo.user_name}
                        onChange={handleChange}
                        onFocus={() => setfocusedName(true)}
                        onBlur={() => setfocusedName(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        name="email"
                        value={registrationInfo.email}
                        onChange={handleChange}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={registrationInfo.password}
                        onChange={handleChange}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="company">
                      Select Company
                    </label>
                    <Input name="company" onChange={handleChange} id="company" value={registrationInfo.company} type="select">
                      <option value="1">Blue Chip</option>
                      <option value="2">Bob Discount</option>
                      
                      
                    </Input>
                  </FormGroup>

                  <Row className="my-4">
                    <Col xs="12">
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="customCheckRegister"
                          name="is_agreeing_to_privacy"
                          checked={registrationInfo.is_agreeing_to_privacy}
                          onChange={handleCheckChange}
                          type="checkbox"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckRegister"
                        >
                          <span className="text-muted">
                            I agree with the privacy:
                            <br />
                            Your data will not be shared/sold
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button
                      disabled={
                        registrationInfo.email.length > 0 &&
                        registrationInfo.password.length > 7 &&
                        registrationInfo.user_name.length > 0
                          ? false
                          : true
                      }
                      className="mt-4"
                      color="info"
                      type="button"
                      onClick={handleClick}
                    >
                      Create account
                    </Button>
                    {registrationInfo.is_reset_button_showing && (
                      <Button
                        className="mt-4"
                        color="info"
                        type="button"
                        onClick={handleResetClick}
                      >
                        Reset
                      </Button>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Register;
