/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import GeneralMessage from "components/Ponce/GeneralMessage";
import Indicator from "components/Ponce/Indicator";
import PropTypes from "prop-types";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

function TimelineHeader({ name, parentName }) {
  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              
              <Col className="mt-10 mt-md-0 text-center" sm="12">
              <GeneralMessage/>
              <Indicator/>
               
              </Col>
            </Row>
          </div>
          
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
