import { call, put } from "redux-saga/effects";

//import { setInventoryItems} from "../../slices/InventorySlice";
import { setItemMasterItems } from "../../slices/ItemMasterSlice";
import { requestViewFile, requestGeneral } from "../requests/index"
//import { requestGetItemMasterItems, requestLoadItemMasterItems, requestProcessAction, requestViewItemMasterItems } from "../requests/ItemMasterRequest";
import { setMessage } from "../../slices/MessageSlice";
import { setIndicator } from "redux/slices/IndicatorSlice";





//The action parameter takes the payload coming from the the call on Customers.js
/* export function* handleGetItemMasterItems(action) {
  console.log("handleGetItemMaster");
  console.log(action)
  try {
    yield put(
      setMessage({
        message: "Gettting item Master...",
        variant: "info",
        isWorking: true,
      })
    );

    const response = yield call(requestGetItemMasterItems, {
      url: "http://localhost/reactAPI/invAPI.php",
      action: "getItemMasterItems",
    });
    const { data } = response;

    try {
   
      JSON.parse(JSON.stringify(data));
      
      
      if (data.status === "success") {
        yield put(setItemMasterItems(data.customers));
       
        yield put(
          setMessage({
            message: "Item master data received",
            variant: "success",
            isWorking: false,
          })
        );
      } else {
        yield put(
          setMessage({
            message: data.message + " error number:" + data.error,
            variant: "danger",
            isWorking: false,
          })
        );
      }
  
    } catch (e) {
      
      yield put(
        setMessage({
          message: data,
          variant: "danger",
          isWorking: false,
      })
      );
      return;
    }



    
    

   

    
  } catch (error) {
   
    yield put(
      setMessage({
        message: "Customers data retrieval failed",
        variant: "warning",
        isWorking: false,
      })
    );
  }
} */

export function* handleViewItemMasterItems(action) {
  //console.log("handleViewItemMasterItems", action.payload);
 
  try {

    yield put(
      setMessage({
        message: "Gettting " + action.payload.fileName + "...",
        variant: "info"
      })
    );
    yield put(setIndicator(true));
    const response = yield call(requestViewFile, {
      ...action.payload
    });
    
    //JSON.parse(JSON.stringify(response));

    /*
    SKUNumber,UPC   ,Name,Size ,Case Pack,Status    ,Retail,,Cost
    2740     ,496340,coke,12 oz,12       ,A - Active,2.59  ,,1.60

    const addresses = [...]; // Some array I got from async call

const uniqueAddresses = Array.from(new Set(addresses.map(a => a.id)))
 .map(id => {
   return addresses.find(a => a.id === id)
 })

 item        ,description    ,quantity on hand,retail,department,department description
 000000001007,12OZ SINGLE CAN,0               ,0.52  ,          ,

    */
    //console.log(response);
    
    
   
        //console.log(response);
        var count = 0;
        var hasHeadings = false;
       
        response.forEach(d=>{
          //d["quantity on hand"] = d["quantity on hand"] * 1;
          if (count === 0) {
            if (d.hasOwnProperty('upc')){
             //console.log(d);
             hasHeadings = true;
            } else {
              hasHeadings = false;
              //console.log("does not have property")
            }
            //console.log(parseInt('195'))
          }
          if (d.hasOwnProperty('retail')){
            d.retail  = d.retail * 1;
          }
          if (d.hasOwnProperty('onhands')){
            d.onhands  = d.onhands * 1;
          }
          if (d.hasOwnProperty('quantity_on_hand')){
            d.quantity_on_hand = d.quantity_on_hand * 1;
          }
          
          
          count++;
          //d.onhands = d.onhands * 1;
        })
        if (hasHeadings) {
          yield put(setItemMasterItems({file_name:action.payload.fileName,file_type: action.payload.fileType,items:response}));
          yield put(
            setMessage({
              message:  action.payload.fileName + " data received",
              variant: "info",
              
            })
          );
        } else {
          yield put(
            setMessage({
              message:  action.payload.fileName + " does not have column headings",
              variant: "danger",
              
            })
          );
        }
        
      
   
    

   

    
    yield put(setIndicator(false));
    //const { data } = response;
    //console.log(data);
  } catch (e) {
    yield put(
      setMessage({
        message: "Data retrieval failed or file was not found",
        variant: "danger"
      })
    );
    yield put(setIndicator(false));
    console.log(e);
  }
}

 export function* handleLoadItemMasterItems(action) {
  console.log("handleLoadItemMasterItems");
  console.log(action);
  try {
    yield put(setIndicator(true));

    /* yield put(
      setMessage({
        message: "Gettting item Master...",
        variant: "info",
        
      })
    ); */
    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload
    });

    console.log("handleLoadItemMasterItems response",response);
    const { data } = response;
    //console.log(JSON.parse(JSON.stringify(data)));
    console.log(data);
    if (data.status === "success") {
       yield put(
        setMessage({
          message: data.message,
          variant: "info",
          
        })
      ); 
      yield put(setIndicator(false));
    } else{
      yield put(setIndicator(false));
       yield put(
        setMessage({
          message: "Error: " + data.message,
          variant: "danger",
          
        })
      );

    }
  } catch (e) {
    yield put(setIndicator(false));
    /* yield put(
      setMessage({
        message: "Item master data retrieval failed",
        variant: "danger",
        
      })
    ); */
    console.log("error");
    console.log(e);
  }
} 
/* 
export function* handleProcessAction(action) {
  console.log("handleProcessAction");
  console.log(action);
  try {
    yield put(
      setMessage({
        message: "Gettting item Master...",
        variant: "info",
        isWorking: true,
      })
    );
    const response = yield call(requestProcessAction, {
      url: "http://localhost/reactAPI/invAPI.php",
      ...action.payload
    });
    const { data } = response;
    console.log(data);
    try {
   
      JSON.parse(JSON.stringify(data));
      
      
      if (data.status === "success") {
        //yield put(setItemMasterItems(data.customers));

       
        yield put(
          setMessage({
            message: "Item master " + data.itemMaster + " has been converted",
            variant: "success",
            isWorking: false,
          })
        );
      } else {
        yield put(
          setMessage({
            message: data.message + " error number:" + data.error,
            variant: "danger",
            isWorking: false,
          })
        );
      }
  
    } catch (e) {
      
      yield put(
        setMessage({
          message: data + " " + e,
          variant: "danger",
          isWorking: false,
      })
      );
      return;
    }
    
  } catch (e) {}
} */

/*
ip_address
backroom_section_start
backroom_section_end
store_file
store_number
*/
