import axios from "axios";
import * as d3 from "d3";

export function requestGeneral(params) {
 
  let url = ""
  const tmp = params.url.split('/');
  //console.log("tmp", tmp.length);
  if (window.location.port * 1 < 4000) {
    url = params.url
    
    
    //console.log("url", url);
  } else {
    
    if (url.includes("tokenAPI")) {

    } else {
      if (tmp.length === 4) {
        url = "/"+ tmp[3];
      } else {
        url = "/"+ tmp[3] +"/" + tmp[4];
      }
    }

  }
  let formData = null;
   
    if (params.method==="post") {
      //console.log(url);
      formData = new FormData();
      if (url.includes("tokenAPI")) {
       
        //formData.append("action",params.action);
        //formData.append("email", params.email);
        //formData.append("uuid", params.uuid);
        //formData.append("token",params.token)
        for ( var key in params ) {
          formData.append(key, params[key]);
        }

        return axios.post(url, formData)
        
      } else {
        //formData = params
        for ( var key1 in params ) {
          formData.append(key1, params[key1]);
        }
        return axios.post(url, formData,{
          headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json'
          }
        })
      }
      

     
      
      
    } else {
      //console.log("paramsx",params)
      return axios.get(url, {
        params: params
      });
    }
}

export function requestViewFile(params) {



   
    let filePath="itemMaster/"  + params.fileName+"?check=" + Math.floor(Math.random() * 1000);
    
    switch(params.page) {
      case "inventory":
         filePath=params.url + "/data/" + params.fileName+"?check=" + Math.floor(Math.random() * 1000);;
        break;
      case "itemMaster":
          filePath=params.url +"/itemMaster/" + params.fileName+"?check=" + Math.floor(Math.random() * 1000);
          break;
      case "home":
        if (params.dir.length > 0) {
          filePath="/"+ params.dir + "/data/" + params.fileName+"?check=" + Math.floor(Math.random() * 1000);
        } else {
          filePath="/data/" + params.fileName+"?check=" + Math.floor(Math.random() * 1000);
        }
        break;
      default:
        break;
    }

    
    return d3.csv(filePath , {
      //return d3.csv(filePath, {
      headers: new Headers({
        "Content-Type": "application/csv",
        Accept: "application/csv",
      }),
    });
  
  }
