import React from "react";




import { useRouteMatch } from "react-router-dom";

import {  useSelector } from "react-redux";

import { Link } from "react-router-dom";
// reactstrap components
import { Card,Badge, Container, Button, CardHeader,Row, Col } from "reactstrap";

import crossfilter from "crossfilter2";
import * as d3 from "d3";
// core components


import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";
import Details from "components/Ponce/Details";

function Area() {
    
    var formatDecimal2 = d3.format(",.2f");
   
    
    const {location, area} = useRouteMatch("/admin/area/:location/:area").params;
    const [dataTable, setDataTable] = React.useState([]);
    const { retailInventory } = useSelector((state) => state.retailInventory);

   

   
    const [ndx, setNDX] = React.useState(null);
    const [groupAll, setGroupAll] = React.useState(null);

   
    //Temp
    //const inventoryStartDate="2022-04-23";
    //const jwt="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7InN0YXR1cyI6InN1Y2Nlc3MiLCJtZXNzYWdlIjoidXNlciB3YXMgZm91bmQiLCJ1c2VyIjpbeyJpZCI6IjEwMDM0IiwiZmlyc3RfbmFtZSI6IkpvaG4iLCJsYXN0X25hbWUiOiJEb2UiLCJlbWFpbCI6ImdpbGJlcnRvMkBwb25jZXNvbHV0aW9ucy5jb20iLCJjb21wYW55X2lkIjoiMiIsImNvbXBhbnkiOlt7ImlkIjoiMiIsImNvbXBhbnlfbmFtZSI6IkJvYiBEaXNjb3VudCIsImNvbXBhbnlfdHlwZSI6IjEiLCJjb21wYW55X2lkIjoiMiIsInN0b3JlX251bWJlciI6IjAwMSIsImNpdHkiOiJSYW5jaG8gQ29yZG92YSIsImRlc2NyaXB0aW9uIjoiU3RvcmUifSx7ImlkIjoiMiIsImNvbXBhbnlfbmFtZSI6IkJvYiBEaXNjb3VudCIsImNvbXBhbnlfdHlwZSI6IjEiLCJjb21wYW55X2lkIjoiMiIsInN0b3JlX251bWJlciI6IjAwMiIsImNpdHkiOiJTYWNyYW1lbnRvIiwiZGVzY3JpcHRpb24iOiJTdG9yZSJ9XX1dLCJyb2xlcyI6W119LCJpYXQiOjE2NTA5OTAyMzcsImV4cCI6MTY1MTAxOTAzN30.NYJDo2alb0WLTztGUC9KakIeOQtpxlMaTGSBK8driD4";

    //let ndx = null;
    //let groupAll = null;
    let sectionDim = null;
    let sectionGroup  = null;
    let sectionTable  = null;

    let departmentDim = null;
    let departmentGroup  = null;
    let departmentTable  = null;


    if (ndx !== null) {
        sectionDim = ndx.dimension((d) => d.inv_section);
        sectionGroup = sectionDim.group();
        departmentDim = ndx.dimension((d) => d.department +"-" + d.deparment_description);
        departmentGroup = departmentDim.group();
    }
    if (sectionDim !== null) {
        sectionTable = sectionGroup.reduceSum(d=> d.inv_quantity).top(Infinity);
        sectionTable.sort((a, b) => a.key - b.key);
    }
    if (departmentDim !== null) {
      departmentTable = departmentGroup.reduceSum(d=> d.extendedTotal).top(Infinity);
      departmentTable.sort((a, b) => a.key - b.key);
  }


    React.useEffect(() => {
       
        if (retailInventory.length > 0) {
          const tempData = retailInventory.filter(d=> {
            return d.inv_area === area;
          })
          setDataTable(tempData)
           
            setNDX(crossfilter(tempData));
            //setGroupAll(ndx.groupAll());
        }

    }, [area, retailInventory]);

    React.useEffect(() => {
       
        if (ndx !== null) {
            
            setGroupAll(ndx.groupAll());
        }

    }, [ndx]);

   

    return (
      <>
        <SimpleHeader name="" parentName="Inventory" />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader className="border-0">
              <h3 className="mb-0">Area {area}</h3>
              <Row>
                <Col className="bg-light text-center" sm={4}>
                  Entries
                </Col>
                <Col className="bg-light text-center" sm={4}>
                  Quantity
                </Col>
                <Col className="bg-light text-center" sm={4}>
                  Amount
                </Col>
                <Col className="text-center" sm={4}>
                  {groupAll !== null && groupAll.reduceCount().value()}
                </Col>
                <Col className="text-center" sm={4}>
                  {groupAll !== null &&
                    formatDecimal2(
                      groupAll.reduceSum((d) => d.inv_quantity).value()
                    )}
                </Col>
                <Col className="text-center" sm={4}>
                  {groupAll !== null &&
                    formatDecimal2(
                      groupAll.reduceSum((d) => d.extendedTotal).value()
                    )}
                </Col>
              </Row>
            </CardHeader>
            <hr/>
            <h1 className="text-center">Sections</h1>
            
            <Row className="text-center mb-2">
              {sectionGroup !== null &&
                sectionTable.map((d) => (
                    <Col key={d.key} md="3">
                      <Button
                        className="buttons-copy buttons-html5"
                        color="secondary"
                        size="sm"
                      >
                        <Link to={"/admin/section/" + location + "/" + area + "/" + d.key}>{d.key}</Link>
                        <Badge color="primary">{d.value}</Badge>
                      </Button>
                    </Col>
                  ))}
            </Row>
            <hr/>
            <h1 className="text-center">Departments</h1>
            
            <Row className="mb-2">
              {departmentTable !== null &&
                departmentTable.map((d) => (
                    <Col key={d.key} md="6">
                      <Button
                        className="buttons-copy buttons-html5"
                        color="secondary"
                        size="sm"
                      >
                        <Link to={"/admin/section/" + location + "/" + d.key}>{d.key}</Link>
                        <Badge color="primary">{"$" + formatDecimal2(d.value)}</Badge>
                      </Button>
                    </Col>
                  ))}
            </Row>
            <Details data={dataTable} fileName={"area_" + area + "_details.csv"}/>
          </Card>
         
        </Container>
      </>
    );

}

export default Area;