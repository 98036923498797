/*!


*/
import React, {useState} from "react";
// react plugin that prints a given react component

// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider,  { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts

import {  useSelector } from "react-redux";
// reactstrap components
import {
  Card,
  Container,
  Row,
} from "reactstrap";
// core components

//import {getCustomers} from "redux/slices/CustomersSlice.js"






const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
           
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function ItemMasterTableView() {
  //const dispatch = useDispatch();

  //const [fileName, setFileName] = useState("ITEM_MASTER.CSV");

  const [columns, setColumns] = useState([
    {
      dataField: "upc",
      text: "Item",
      sort: true,
      csvType:"number",
      
    }]);

  const { items} = useSelector((state) => state.itemMasterItems);

  //console.log(items);

  


  function isRegProperty(obj) {
    var t = typeof obj;
    return t;
  } 


 React.useEffect(() => {
  if (items.length > 0) {
    const cols = [];
    const keys = Object.keys(items[0]);
    
    for (let i = 0; i < keys.length; i++) {
      
      const a = isRegProperty(items[0][keys[i]])=== "number" ? "right":"left";
      
      const c = {dataField:keys[i],
                 text:keys[i],
                 sort:true,
                 headerAlign: a,
                 align:a,
                 csvType:"number"}
      cols.push(c); 
      
    }
    setColumns(cols)
    
  } 
  
}, [items]);



  /* React.useEffect(() => {
    items.length=== 0 && 
    dispatch(getCustomers({action:"getCustomers", method:"get", url:"http://192.168.1.64:4001/customer" }));
    
  }, [dispatch]); */



 

 

 

  
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file

  //const { ExportCSVButton } = CSVExport;
  

  return (
    <>
    
      <Container  fluid>
        <Row>
          <div className="col">
            <Card>
              {/* <CardHeader>
                <h3 className="mb-0">React Bootstrap Table 2</h3>
                <p className="text-sm mb-0">
                  This is an exmaple of data table using the well known
                  react-bootstrap-table2 plugin. This is a minimal setup in
                  order to get started fast.
                </p>
              </CardHeader> */}
              {items.length > 0 && (
                <ToolkitProvider
                  data={items}
                  keyField="upc"
                  columns={columns}
                  search
                  //exportCSV={{
                  //  fileName: "ITEM_MASTER.CSV",
                  //  separator: ",",
                  //  blobType: "text/csv;charset=ansi",
                  //}}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 pb-1"
                        >
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                          <br />

                          {/* <ExportCSVButton {...props.csvProps}>
                            Export CSV!!
                          </ExportCSVButton> */}
                        </div>
                      }

                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        striped
                        pagination={pagination}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
            {/* <Card>
              <CardHeader>
                <h3 className="mb-0">Action buttons</h3>
                <p className="text-sm mb-0">
                  This is an exmaple of data table using the well known
                  react-bootstrap-table2 plugin. This is a minimal setup in
                  order to get started fast.
                </p>
              </CardHeader>
              <ToolkitProvider
                data={dataTable}
                keyField="name"
                columns={[
                  {
                    dataField: "name",
                    text: "Name",
                    sort: true,
                  },
                  {
                    dataField: "position",
                    text: "Position",
                    sort: true,
                  },
                  {
                    dataField: "office",
                    text: "Office",
                    sort: true,
                  },
                  {
                    dataField: "age",
                    text: "Age",
                    sort: true,
                  },
                  {
                    dataField: "start_date",
                    text: "Start date",
                    sort: true,
                  },
                  {
                    dataField: "salary",
                    text: "Salary",
                    sort: true,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={12} sm={6}>
                          <ButtonGroup>
                            <Button
                              className="buttons-copy buttons-html5"
                              color="default"
                              size="sm"
                              id="copy-tooltip"
                              onClick={() =>
                                copyToClipboardAsTable(
                                  document.getElementById("react-bs-table")
                                )
                              }
                            >
                              <span>Copy</span>
                            </Button>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  color="default"
                                  size="sm"
                                  className="buttons-copy buttons-html5"
                                  id="print-tooltip"
                                >
                                  Print
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                          </ButtonGroup>
                          <UncontrolledTooltip
                            placement="top"
                            target="print-tooltip"
                          >
                            This will open a print page with the visible rows of
                            the table.
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            placement="top"
                            target="copy-tooltip"
                          >
                            This will copy to your clipboard the visible rows of
                            the table.
                          </UncontrolledTooltip>
                        </Col>
                        <Col xs={12} sm={6}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      ref={componentRef}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      id="react-bs-table"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card> */}
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ItemMasterTableView;
