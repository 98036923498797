import React from "react";


import { useRouteMatch } from "react-router-dom";

import {  useSelector } from "react-redux";

import { Link } from "react-router-dom";
// reactstrap components
import { Card,Badge, Container, Button, CardHeader,Row, Col } from "reactstrap";

import crossfilter from "crossfilter2";
import * as d3 from "d3";
// core components


import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";
import Details from "components/Ponce/Details";

function Section() {

    var formatDecimal2 = d3.format(",.2f");
   
    
    const {location, area, section} = useRouteMatch("/admin/section/:location/:area/:section").params;
    const [dataTable, setDataTable] = React.useState([]);
    const { retailInventory } = useSelector((state) => state.retailInventory);

    //console.log(useRouteMatch("/admin/area/:location/:area").params);

   
    const [ndx, setNDX] = React.useState(null);
    const [groupAll, setGroupAll] = React.useState(null);

    
    //Temp
    
    

    let departmentDim = null;
    let departmentGroup  = null;
    let departmentTable  = null;


    if (ndx !== null) {
         departmentDim = ndx.dimension((d) => d.department +"-" + d.deparment_description);
        departmentGroup = departmentDim.group();
    }
    
    if (departmentDim !== null) {
      departmentTable = departmentGroup.reduceSum(d=> d.extendedTotal).top(Infinity);
      departmentTable.sort((a, b) => a.key - b.key);
  }


    React.useEffect(() => {
       
        if (retailInventory.length > 0) {
          const tempData = retailInventory.filter(d=> {
            //console.log(area);
            if (area==="0") {
              //console.log("0");
              return d.inv_section === section;
            } else {
            return d.inv_area === area && d.inv_section === section;
            }
          })
          setDataTable(tempData)
           
            setNDX(crossfilter(tempData));
            //setGroupAll(ndx.groupAll());
        }

    }, [area, retailInventory]);

    React.useEffect(() => {
       
        if (ndx !== null) {
            
            setGroupAll(ndx.groupAll());
        }

    }, [ndx]);

   

    return (
      <>
        <SimpleHeader name="" parentName="Inventory" />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader className="border-0">
              <h3 className="mb-0">Area {area} Section {section}</h3>
              <Row>
                <Col className="bg-light text-center" sm={4}>
                  Entries
                </Col>
                <Col className="bg-light text-center" sm={4}>
                  Quantity
                </Col>
                <Col className="bg-light text-center" sm={4}>
                  Amount
                </Col>
                <Col className="text-center" sm={4}>
                  {groupAll !== null && groupAll.reduceCount().value()}
                </Col>
                <Col className="text-center" sm={4}>
                  {groupAll !== null &&
                    formatDecimal2(
                      groupAll.reduceSum((d) => d.inv_quantity).value()
                    )}
                </Col>
                <Col className="text-center" sm={4}>
                  {groupAll !== null &&
                    formatDecimal2(
                      groupAll.reduceSum((d) => d.extendedTotal).value()
                    )}
                </Col>
              </Row>
            </CardHeader>
           
            <hr/>
            <h1 className="text-center">Departments</h1>
            
            <Row className="mb-2">
              {departmentTable !== null &&
                departmentTable.map((d) => (
                    <Col key={d.key} md="6">
                      <Button
                        className="buttons-copy buttons-html5"
                        color="secondary"
                        size="sm"
                      >
                        <Link to={"/admin/section/" + location + "/" + d.key}>{d.key}</Link>
                        <Badge color="primary">{"$" + formatDecimal2(d.value)}</Badge>
                      </Button>
                    </Col>
                  ))}
            </Row>
            <Details data={dataTable} fileName={"area_" + area +"_section_" + section +"_details.csv"}/>
          </Card>
         
        </Container>
      </>
    );

}

export default Section;