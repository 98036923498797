import React from "react";


import { useRouteMatch } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
// reactstrap components
import { Card,Badge, Container, Button, CardHeader,Row, Col } from "reactstrap";

import crossfilter from "crossfilter2";
import * as d3 from "d3";
// core components
import { getRetailInventory } from "redux/slices/RetailInventorySlice";
//import { setIndicator } from "redux/slices/IndicatorSlice";
//import { setMessage } from "redux/slices/MessageSlice";
import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";

function Location() {
    const dispatch = useDispatch();
    var formatDecimal2 = d3.format(",.2f");
    
    const { ip } = useSelector((state) => state.ip);
    const {storeNumber, startDate} = useRouteMatch("/admin/location/:storeNumber/:startDate").params;
    const [dataTable, setDataTable] = React.useState([]);
    const { retailInventory } = useSelector((state) => state.retailInventory);
    const { token } = useSelector((state) => state.token);

   
    const [ndx, setNDX] = React.useState(null);
    const [groupAll, setGroupAll] = React.useState(null);

   
    //Temp
    //const inventoryStartDate="2022-04-23";
    //const jwt="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7InN0YXR1cyI6InN1Y2Nlc3MiLCJtZXNzYWdlIjoidXNlciB3YXMgZm91bmQiLCJ1c2VyIjpbeyJpZCI6IjEwMDM0IiwiZmlyc3RfbmFtZSI6IkpvaG4iLCJsYXN0X25hbWUiOiJEb2UiLCJlbWFpbCI6ImdpbGJlcnRvMkBwb25jZXNvbHV0aW9ucy5jb20iLCJjb21wYW55X2lkIjoiMiIsImNvbXBhbnkiOlt7ImlkIjoiMiIsImNvbXBhbnlfbmFtZSI6IkJvYiBEaXNjb3VudCIsImNvbXBhbnlfdHlwZSI6IjEiLCJjb21wYW55X2lkIjoiMiIsInN0b3JlX251bWJlciI6IjAwMSIsImNpdHkiOiJSYW5jaG8gQ29yZG92YSIsImRlc2NyaXB0aW9uIjoiU3RvcmUifSx7ImlkIjoiMiIsImNvbXBhbnlfbmFtZSI6IkJvYiBEaXNjb3VudCIsImNvbXBhbnlfdHlwZSI6IjEiLCJjb21wYW55X2lkIjoiMiIsInN0b3JlX251bWJlciI6IjAwMiIsImNpdHkiOiJTYWNyYW1lbnRvIiwiZGVzY3JpcHRpb24iOiJTdG9yZSJ9XX1dLCJyb2xlcyI6W119LCJpYXQiOjE2NTA5OTAyMzcsImV4cCI6MTY1MTAxOTAzN30.NYJDo2alb0WLTztGUC9KakIeOQtpxlMaTGSBK8driD4";

    //let ndx = null;
    //let groupAll = null;
    let areaDim = null;
    let areaGroup  = null;
    let areaTable  = null;

    let sectionDim = null;
    let sectionGroup  = null;
    let sectionTable  = null;

    let departmentDim = null;
    let departmentGroup  = null;
    let departmentTable  = null;

    if (ndx !== null) {
        areaDim = ndx.dimension((d) => d.inv_area);
        areaGroup = areaDim.group();
        sectionDim = ndx.dimension((d) => d.inv_section);
        sectionGroup = sectionDim.group();
        departmentDim = ndx.dimension((d) => d.department +"-" + d.deparment_description);
        departmentGroup = departmentDim.group();
    }
    if (areaDim !== null) {
        areaTable = areaGroup.reduceSum(d => d.inv_quantity).top(Infinity);
        areaTable.sort((a, b) => a.key - b.key);

    }
    if (sectionDim !== null) {
        sectionTable = sectionGroup.reduceSum(d=> d.inv_quantity).top(Infinity);
        sectionTable.sort((a, b) => a.key - b.key);
    }
    if (departmentDim !== null) {
        departmentTable = departmentGroup.reduceSum(d=> d.extendedTotal).top(Infinity);
        departmentTable.sort((a, b) => a.key - b.key);
    }

    React.useEffect(() => {
       
        if (retailInventory.length > 0) {
            
            setNDX(crossfilter(retailInventory));
            //setGroupAll(ndx.groupAll());
        }

    }, [retailInventory]);

    React.useEffect(() => {
       
        if (ndx !== null) {
            
            setGroupAll(ndx.groupAll());
        }

    }, [ndx]);

    React.useEffect(() => {
        retailInventory.length === 0 &&
          ip.length === 1 &&
          dispatch(
            getRetailInventory({
              action: "getStoreInventory",
              storeNumber:storeNumber,
              inventoryStartDate:startDate,
              jwt:token.jwt,
              method: "post",
              url:
                ip[0].ip +
                "/inventory/inventoryAPI.php",
            })
          );
      }, [dispatch, storeNumber, ip, retailInventory.length]);

    /* React.useEffect(() => {
        const fetchInventory = async () => {
            dispatch(setIndicator(true));
            let formData = null;
            formData = new FormData();
            formData.append("action","getStoreInventory");
            formData.append("storeNumber",id);
            formData.append("inventoryStartDate",inventoryStartDate);
            formData.append("jwt",jwt);
            axios
            .post("http://" + ip[0].ip +":" + ip[0].websocket_port +"/inventory/inventoryAPI.php", formData)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === "success") {
                  response.data.inventory.forEach(d=>{
                      d.inv_sequence = d.inv_sequence * 1;
                      d.inv_quantity = d.inv_quantity * 1;
                      d.item_price = Math.round(d.item_price * 1,2);
                      d.original_quantity = d.original_quantity * 1;
                      d.quantity_on_hand = d.quantity_on_hand * 1;
                      d.extendedTotal = Math.round(d.inv_quantity * d.item_price,2)
                  })

                setDataTable(response.data.inventory);
                  
                //dispatch(setToken(response.data))
                dispatch(
                    setMessage({ message: response.data.message+"", variant: "info" })
                  );
                
              } else {
               dispatch(
                 setMessage({ message: response.data.message+"", variant: "danger" })
               );
       
              }
              dispatch(setIndicator(false));
       
              
              
              
              //dispatch(setCustomers(response.data.data))
              //dispatch(setIndicator(false));
              //hideModal(false)
              //callback(response.data);
            })
            .catch((error) => {
              dispatch(setIndicator(false));
              //dispatch(setMessage({ message: error, variant: "danger" }));
              console.log(error);
              //console.log("*****  "+error)
            });
            
        };
        ip.length === 1 && fetchInventory();
        
      }, [dispatch, ip, ip.length]); */

    return (
      <>
        <SimpleHeader name="" parentName="Inventory" />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader className="border-0">
              <h3 className="mb-0">Store</h3>
              <Row>
                <Col className="bg-light text-center" sm={4}>
                  Entries
                </Col>
                <Col className="bg-light text-center" sm={4}>
                  Quantity
                </Col>
                <Col className="bg-light text-center" sm={4}>
                  Amount
                </Col>
                <Col className="text-center" sm={4}>
                  {groupAll !== null && groupAll.reduceCount().value()}
                </Col>
                <Col className="text-center" sm={4}>
                  {groupAll !== null &&
                    formatDecimal2(
                      groupAll.reduceSum((d) => d.inv_quantity).value()
                    )}
                </Col>
                <Col className="text-center" sm={4}>
                  {groupAll !== null &&
                    formatDecimal2(
                      groupAll.reduceSum((d) => d.extendedTotal).value()
                    )}
                </Col>
              </Row>
            </CardHeader>
            <hr/>
            <h1 className="text-center">Areas</h1>
           
            <Row className="text-center mb-2">
              {areaGroup !== null &&
                areaTable.map((d) => (
                    <Col key={d.key} md="3">
                      <Button
                        className="buttons-copy buttons-html5"
                        color="secondary"
                        size="sm"
                      >
                        <Link to={"/admin/area/" + storeNumber + "/" + d.key}>{d.key}</Link>
                        <Badge color="primary">{d.value}</Badge>
                      </Button>
                    </Col>
                  ))}
            </Row>
            <hr/>
            <h1 className="text-center">Sections</h1>
            
            <Row className="text-center mb-2">
              {sectionGroup !== null &&
                sectionTable.map((d) => (
                    <Col key={d.key} md="3">
                      <Button
                        className="buttons-copy buttons-html5"
                        color="secondary"
                        size="sm"
                      >
                        <Link to={"/admin/section/" + storeNumber + "/0/" + d.key}>{d.key}</Link>
                        <Badge color="primary">{d.value}</Badge>
                      </Button>
                    </Col>
                  ))}
            </Row>
            <hr/>
            <h1 className="text-center">Departments</h1>
            
            <Row className="mb-2">
              {departmentGroup !== null &&
                departmentTable.map((d) => (
                    <Col key={d.key} md="6">
                      <Button
                        className="buttons-copy buttons-html5"
                        color="secondary"
                        size="sm"
                      >
                        <Link to={"/admin/section/" + storeNumber + "/" + d.key}>{d.key}</Link>
                        <Badge color="primary">{"$" + formatDecimal2(d.value)}</Badge>
                      </Button>
                    </Col>
                  ))}
            </Row>
          </Card>
        </Container>
      </>
    );

}

export default Location;