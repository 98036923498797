import React from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import { Card, Container, Button, CardHeader, Row, Col } from "reactstrap";



// core components
import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";


function Locations(props) {


  //These are necessary for the table
  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
             
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  

  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "df1",
      csvExport: false,
      isDummyField: true,
      text: "Actions",
      csvType:"number",
      formatter: (cellContent, row) => {
        
        return (
          <>
           
            <Button
              className="buttons-copy buttons-html5"
              color="success"
              size="sm"
              
            >
              <Link to={"/admin/location/" + row.store_number + "/" + row.start_date}>
                <i
                  
                  id={"btn-select-"}
                  title="Go to this item"
                  className="fa fa-barcode "
                  aria-hidden="true"
                ></i>
              </Link>
            </Button>
            

           

            
            
          </>
        );
      },
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
      csvType:"number",
      
    },
    {
      dataField: "store_number",
      text: "Store Number",
      csvType:"number",
      sort: true,
    },
    {
      dataField: "start_date",
      text: "Inventory start ate",
      csvType:"number",
      sort: true,
    }
  ]);

    //Temp
    //const jwt="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7InN0YXR1cyI6InN1Y2Nlc3MiLCJtZXNzYWdlIjoidXNlciB3YXMgZm91bmQiLCJ1c2VyIjpbeyJpZCI6IjEwMDM0IiwiZmlyc3RfbmFtZSI6IkpvaG4iLCJsYXN0X25hbWUiOiJEb2UiLCJlbWFpbCI6ImdpbGJlcnRvMkBwb25jZXNvbHV0aW9ucy5jb20iLCJjb21wYW55X2lkIjoiMiIsImNvbXBhbnkiOlt7ImlkIjoiMiIsImNvbXBhbnlfbmFtZSI6IkJvYiBEaXNjb3VudCIsImNvbXBhbnlfdHlwZSI6IjEiLCJjb21wYW55X2lkIjoiMiIsInN0b3JlX251bWJlciI6IjAwMSIsImNpdHkiOiJSYW5jaG8gQ29yZG92YSIsImRlc2NyaXB0aW9uIjoiU3RvcmUifSx7ImlkIjoiMiIsImNvbXBhbnlfbmFtZSI6IkJvYiBEaXNjb3VudCIsImNvbXBhbnlfdHlwZSI6IjEiLCJjb21wYW55X2lkIjoiMiIsInN0b3JlX251bWJlciI6IjAwMiIsImNpdHkiOiJTYWNyYW1lbnRvIiwiZGVzY3JpcHRpb24iOiJTdG9yZSJ9XX1dLCJyb2xlcyI6W119LCJpYXQiOjE2NTA4MzQyMTksImV4cCI6MTY1MDg2MzAxOX0.CkMKLrF11v-MtDuIY3kZO1PeOWb0qlxGXswZNkdobWM"
    const [storeLocations, setStoreLocations] = React.useState([
      /* {
          "id": "2",
          "company_name": "Bob Discount",
          "company_type": "1",
          "company_id": "2",
          "store_number": "001",
          "city": "Rancho Cordova",
          "description": "Store"
      }, */
      /* {
          "id": "2",
          "company_name": "Bob Discount",
          "company_type": "1",
          "company_id": "2",
          "store_number": "002",
          "city": "Sacramento",
          "description": "Store"
      } */
  ]);



  const [fileName, setFileName] = React.useState("store_locations.csv");

  const { token } = useSelector((state) => state.token);
  

  React.useEffect(() => {
    if ( token.hasOwnProperty("user") ) {
      setStoreLocations(token.user[0].company)
         


         //console.log(admin,admin.length );
       } 
 }, [token]);

  
  

  

  return (
    <>
      <SimpleHeader name="" parentName="Inventory" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            
            <Row>
                <Col className="bg-light text-center" sm={4}>
                Entries


                </Col>
                <Col className="bg-light text-center"  sm={4}>
                Quantity

                </Col>
                <Col className="bg-light text-center"  sm={4}>
                Amount

                </Col>
                
              </Row>
          </CardHeader>
          <ToolkitProvider
              data={storeLocations}          //To be changed
              keyField="key" //To be changed
              columns={tableColumns}
              search
              exportCSV={{
                //fileName: "areaTotals.csv",
                fileName: fileName,
                separator: ",",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div className="py-4 table-responsive">
                  <div
                    id="datatable-basic_filter"
                    className="dataTables_filter px-4 pb-1"
                  >
                    <label>
                      Search:
                      <SearchBar
                        className="form-control-sm"
                        placeholder=""
                        {...props.searchProps}
                      />
                    </label>
                    <br />
                    <ExportCSVButton {...props.csvProps}>
                      Export {fileName}
                    </ExportCSVButton>
                  </div>
                  
                  

                  <BootstrapTable
                    {...props.baseProps}
                    pagination={pagination}
                    striped
                    bootstrap4={true}
                    bordered={false}
                    
                  />
                </div>
              )}
            </ToolkitProvider>
          
        </Card>
      </Container>
    </>
  );
}

export default Locations;
