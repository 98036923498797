/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
 
} from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbarPonce.js";

import AuthFooter from "components/Footers/AuthFooterPonce.js";

function Index() {
  return (
    <>
      <IndexNavbar />
      <div className="main-content">
       
        <section className="py-6 pb-9 bg-default">
          <Container fluid>
            <Row className="justify-content-center text-center">
              <Col md="6">
                <h2 className="display-3 text-white">
                Inventory Portal - Get your Count Results
                </h2>
                <p className="lead text-white">
                  We are creating an easy interface for your retail inventory needs<br/>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg pt-lg-0 mt--7">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <Row className="justify-content-center">
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                          <i className="ni ni-check-bold" />
                        </div>
                        <h4 className="h3 text-info text-uppercase">
                        <a href="https://www.countresults.com/bc">Blue Chip Inventory Portal</a>
                         
                        </h4>
                        <p className="description mt-3">
                         You will be able use this portal to load master files used during inventory.
                        </p>
                        
                      </CardBody>
                    </Card>
                  </Col>
                  {/* <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                          <i className="ni ni-istanbul" />
                        </div>
                        <h4 className="h3 text-success text-uppercase">
                          Download Inventory files
                        </h4>
                        <p className="description mt-3">
                          Once the inventory has been completed, you will be able to download the inventory files.
                        </p>
                        
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                          <i className="ni ni-planet" />
                        </div>
                        <h4 className="h3 text-warning text-uppercase">
                          View previous inventory
                        </h4>
                        <p className="description mt-3">
                          We will be able to maintain on this portal all your previous inventory data
                        </p>
                        
                      </CardBody>
                    </Card>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <AuthFooter />
    </>
  );
}

export default Index;
