import { takeLatest } from "redux-saga/effects";

// Handlers
import { handleCreateFile, handleGetRetailInventory } from "./handlers/RetailInventoryHandler";
import { handleViewItemMasterItems } from "./handlers/ItemMasterHandler";
import { handleViewIP} from "./handlers/IPHandler";

//Slices
import { createFile, getRetailInventory} from "redux/slices/RetailInventorySlice";
import { viewItemMasterItems, } from "../slices/ItemMasterSlice";
import {viewIP} from "../slices/IPSlice";




export function* watcherSaga() {
  yield takeLatest(createFile.type, handleCreateFile);
  yield takeLatest(getRetailInventory.type, handleGetRetailInventory);
  yield takeLatest(viewIP.type, handleViewIP);
  yield takeLatest(viewItemMasterItems.type, handleViewItemMasterItems);
  
}
