import {
    configureStore,
    combineReducers,
    getDefaultMiddleware,
  } from "@reduxjs/toolkit";
  import createSagaMiddleware from "redux-saga";
  import { watcherSaga } from "./sagas/rootSaga";

  //Added
  import indicatorSlice from "./slices/IndicatorSlice";
  import ipSlice from "./slices/IPSlice";
  import itemMasterSlice from "./slices/ItemMasterSlice";
  import messageSlice from "./slices/MessageSlice";
  import retailInventorySlice from "./slices/RetailInventorySlice";
  import tokenSlice from "./slices/TokenSlice";
 
  
  
  const sagaMiddleware = createSagaMiddleware();
  
  const reducer = combineReducers({
    
    ip:ipSlice,
    isProcessing:indicatorSlice,
    itemMasterItems:itemMasterSlice,
    message: messageSlice,
    retailInventory:retailInventorySlice,
    token:tokenSlice,
    
  });
  
  const store = configureStore({
    reducer,
    middleware: [...getDefaultMiddleware({ thunk: false,immutableCheck: false,serializableCheck:false }), sagaMiddleware],
  });
  
  sagaMiddleware.run(watcherSaga);
  
  export default store;
  