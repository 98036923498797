/*!


*/
import React, {useRef} from "react";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";
import NotificationAlert from 'react-notification-alert';

// core components
//import PanelHeader from "components/PanelHeader/PanelHeader.js";

function GeneralMessage() {
  const { message } = useSelector((state) => {
    return state;
  });

 

  const notify = useRef(null);

  React.useEffect(() => {
    myFunc()
    
  }, [message]);

  function myFunc(){
    //console.log("myFunc");
   //notify.notificationAlert(options);
   notify.current.notificationAlert(options);
}

  let options = {
    place: "tr",
    message: (
      <div className="alert-text">
        <span className="alert-title" data-notify="title">
          
          {message.message}
        </span>
        {/* <span data-notify="message">
          Turning standard Bootstrap alerts into awesome notifications
        </span> */}
      </div>
    ),
    type: "primary",
    autoDismiss: 3
  };

  return (
    <>
      {message.message.length > 0 && (
        <>
        <Alert color={message.variant}>{message.message}</Alert>
        <NotificationAlert ref={notify} zIndex={9999} onClick={() => console.log("hey")} />
        </>
      )}
    </>
  );
}

export default GeneralMessage;
